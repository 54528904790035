import React from 'react';
import styled from 'styled-components';
import SEO from '../components/seo';
import Layout from '../layouts/Layout';
import Section from '../components/shared/wraps/Section/Section';
import BigTitle from '../components/shared/text/BigTitle';
import Content from '../components/shared/Content';
import ReactHtmlParser from 'react-html-parser';
import { graphql } from 'gatsby';
import Image from 'gatsby-image';

const StyledSection = styled(Section)`
  margin-bottom: 10rem;
`;

const ImageWrapper = styled.div`
  max-width: 120rem;
  margin: auto;
`;

const StyledBigTitle = styled(BigTitle)`
  margin-bottom: 3.5rem;
  @media screen and (max-width: 767px) {
    line-height: 1;
  }
`;

export const query = graphql`
  query navQuery($id: String!) {
    directusNawigacja(id: { eq: $id }) {
      title
      content
      slug
      images {
        title
        localFile {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    }
  }
`;

const WhatWeDo = ({ data, location: { pathname: path } }) => {
  const article = data.directusNawigacja;
  const { slug } = article;
  const sortedImages = article.images.sort((a, b) =>
    a.title.toUpperCase() > b.title.toUpperCase() ? 1 : -1,
  );
  return (
    <Layout path={path} id={slug} nonBgc>
      <SEO title={`Co robimy? - ${article.title}`} />
      <StyledSection nonGrid site first nonFlex>
        <StyledBigTitle small>{article.title}</StyledBigTitle>
        <Content>{ReactHtmlParser(article.content)}</Content>
        {sortedImages.map(image => (
          <ImageWrapper key={image.title}>
            <Image
              fluid={image.localFile.childImageSharp.fluid}
              alt={article.title}
            />
          </ImageWrapper>
        ))}
      </StyledSection>
    </Layout>
  );
};

export default WhatWeDo;
